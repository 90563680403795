"use client";

import classNames from "classnames/bind";
import {
  selectShowGreenDot,
  selectShowHighInSugarIcon,
} from "../../helpers/selectors";
import highInSugarIconClUrl from "./high-in-sugar-icon--cl.svg";
import highInSugarIconUrl from "./high-in-sugar-icon.svg";
import glutenFreeIconUrl from "./icons/gluten-free.svg";
import halalIconUrl from "./icons/halal.svg";
import lactoseFreeIconUrl from "./icons/lactose-free.svg";
import veganIconUrl from "./icons/vegan.svg";
import vegetarianUrl from "./vegetarian-mark.svg";
import NSFUrl from "./icons/label-nsf.svg";
import styles from "./dietary-marks.module.css";
import { useLocaleStringContext } from "../../helpers/LocaleStringContext";
import { useTranslationsContext } from "../../helpers/TranslationsContext";
import template from "../../helpers/template";
import { Fragment } from "react";

const cx = classNames.bind(styles);

export type DietaryMarkType =
  | "vegetarian"
  | "high-sugar"
  | "vegan"
  | "gluten-free"
  | "lactose-free"
  | "halal"
  | "nsf";

export interface DietaryMarksProps {
  kind: DietaryMarkType | DietaryMarkType[];
  sugarFree?: boolean;
  className?: string;
}

export const DietaryMarks = ({
  className,
  kind,
  sugarFree = false,
}: DietaryMarksProps) => {
  const localeString = useLocaleStringContext();
  const translations = useTranslationsContext();

  const showVegetarianMark = selectShowGreenDot(localeString);
  const showHighInSugarMark = selectShowHighInSugarIcon(localeString);

  /* When styling it's useful to force all marks to appear */
  // const showVegetarianMark = true;
  // const showHighInSugarMark = true;

  const highSugarUrl =
    localeString === "cl-es"
      ? highInSugarIconClUrl.src
      : highInSugarIconUrl.src;

  return (
    <>
      {(Array.isArray(kind) ? kind : [kind]).map((kind, index) => (
        <Fragment key={index}>
          {showHighInSugarMark && kind === "high-sugar" && !sugarFree && (
            <img
              src={highSugarUrl}
              alt={template(
                translations["dietaryMarks.alt.highSugar"],
                "High sugar mark",
              )}
              className={cx("high-sugar", className)}
              data-localestring={localeString}
            />
          )}
          {showVegetarianMark && kind === "vegetarian" && (
            <img
              src={vegetarianUrl.src}
              alt={template(
                translations["dietaryMarks.alt.vegetarian"],
                "Vegetarian mark",
              )}
              className={cx("vegetarian", className)}
              data-localestring={localeString}
            />
          )}
          {kind === "vegan" && (
            <img
              src={veganIconUrl.src}
              alt={template(
                translations["dietaryMarks.alt.vegan"],
                "Vegan mark",
              )}
              data-localestring={localeString}
            />
          )}
          {kind === "lactose-free" && (
            <img
              src={lactoseFreeIconUrl.src}
              alt={template(
                translations["dietaryMarks.alt.lactoseFree"],
                "Lactose Free mark",
              )}
              data-localestring={localeString}
            />
          )}
          {kind === "gluten-free" && (
            <img
              src={glutenFreeIconUrl.src}
              alt={template(
                translations["dietaryMarks.alt.gluten"],
                "Gluten Free mark",
              )}
              data-localestring={localeString}
            />
          )}
          {kind === "halal" && (
            <img
              src={halalIconUrl.src}
              alt={template(
                translations["dietaryMarks.alt.halal"],
                "Halal mark",
              )}
              data-localestring={localeString}
            />
          )}
          {kind === "nsf" && (
            <img
              src={NSFUrl.src}
              alt={template(translations["dietaryMarks.alt.nsf"], "NSF mark")}
              data-localestring={localeString}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
